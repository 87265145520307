import React, { useState } from "react";
import {
  Container,
  ListItem,
  Title,
  Icon,
} from "../../styles/mobile/contentList";
import { isNil } from "lodash";

const MobileContentList = ({ items, setImageFromChild }) => {
  const [active, setActive] = useState(0);
  const handleItemClick = (index) => {
    setActive(index);
    setImageFromChild(index);
  };
  return (
    <Container>
      {items.map((item, index) => {
        return (
          <ListItem
            active={active === index ? true : false}
            onClick={() => handleItemClick(index)}
            index={index}
          >
            {!isNil(item.icon) && <Icon>{item.icon}</Icon>}
            <Title active={active === index ? true : false}>{item.title}</Title>
          </ListItem>
        );
      })}
    </Container>
  );
};

export default MobileContentList;
