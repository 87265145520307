import styled from "styled-components";

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 30px;
`;

export const Headline = styled.div`
  font-size: 20px;
  line-height: 30px;
  margin: 40px 0;
`;

export const Heading = styled.div`
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin-top: 20px;
`;
