import React, { useEffect, useState } from "react";
import { Container } from "../styles/landingPage";
import NavBar from "../components/navBar";
import {
  PageContainer,
  Header,
  ContainerPricing,
  PlansCard,
  ActionsAndText,
  Text,
  TextWithCTA,
  CTA,
  PlansContainer,
  PlanContainer,
  PlanHeader,
  PlanPrice,
  PlanPriceNumber,
  PlanPriceSign,
  PlanPriceUnit,
  PlanDescription,
  PlanLineItemsContainer,
  PlanLineItem,
  PlanButton,
  OtherSolutionsCard,
  OtherSolutionsText,
  OtherSolutionsHeader,
  OtherSolutionsSubtext,
  OtherSolutionsButton,
  SubHeader,
  PlanPricePerBill,
  SpecialContainer,
  EmptySpace,
} from "../styles/pricing.js";
import SameColorToggle from "../components/SameColorToggle/SameColorToggle.js";

const Pricing = () => {
  useEffect(() => {
    var url = new URLSearchParams(window.location.search);
    if (url.has("api")) {
      if (url.get("api")) {
        setPricingType("api");
      }
    }
  }, []);
  const [period, setPeriod] = useState("annual");
  const [pricingType, setPricingType] = useState("subscription");
  return (
    <Container>
      <NavBar />
      <ContainerPricing>
        <Header>Pricing plans for teams of all sizes</Header>
        <SubHeader>
          Get started with AI powered utility bill processing. Cancel any time.
        </SubHeader>
        <PlansCard>
          <ActionsAndText>
            {pricingType === "subscription" && (
              <SameColorToggle
                option1={"Annual"}
                option2={"Monthly"}
                setPeriod={setPeriod}
              />
            )}

            <TextWithCTA>
              {pricingType === "subscription" && (
                <>
                  <Text>Interested in our API solutions?</Text>
                  <CTA onClick={() => setPricingType("api")}>
                    View our API pricing
                  </CTA>
                </>
              )}
              {pricingType === "api" && (
                <>
                  <Text>Interested in our platform solutions?</Text>
                  <CTA onClick={() => setPricingType("subscription")}>
                    View our subscription pricing
                  </CTA>
                </>
              )}
            </TextWithCTA>
          </ActionsAndText>
          <PlansContainer>
            {pricingType === "subscription" && (
              <>
                <PlanContainer>
                  <PlanHeader>Pay as you go</PlanHeader>
                  <PlanPrice>
                    <PlanPriceNumber color={"#3958FF"}>2</PlanPriceNumber>
                    <PlanPriceSign color={"#3958FF"}>$</PlanPriceSign>
                  </PlanPrice>
                  <PlanPriceUnit>per utility bill</PlanPriceUnit>
                  <EmptySpace />
                  <PlanDescription height={"94px"}>
                    Perfect for occasional users, this plan allows you to pay
                    just $2 per utility bill. Enjoy the flexibility of 2 admin
                    seats to manage your account efficiently, making it ideal
                    for businesses with varying needs.
                  </PlanDescription>
                  <PlanLineItemsContainer>
                    <PlanLineItem>2 seats per admin account</PlanLineItem>
                  </PlanLineItemsContainer>
                  <SpecialContainer>
                    <a
                      href="https://app.buildupward.com/signup?pricing=true"
                      style={{ textDecoration: "none" }}
                    >
                      <PlanButton color={"#3958FF"} hoverColor={"#0A2fff"}>
                        Select
                      </PlanButton>
                    </a>
                  </SpecialContainer>
                </PlanContainer>
                <PlanContainer>
                  <PlanHeader>Starter</PlanHeader>
                  <PlanPrice>
                    <PlanPriceNumber color={"#AA5EEA"}>
                      {period === "annual" ? "135" : "150"}
                    </PlanPriceNumber>
                    <PlanPriceSign color={"#AA5EEA"}>$</PlanPriceSign>
                  </PlanPrice>
                  <PlanPriceUnit>
                    {period === "annual"
                      ? "per month ($1,620 billed annually)"
                      : "per month"}
                  </PlanPriceUnit>
                  <PlanPricePerBill color={"#AA5EEA"}>
                    {period === "annual" ? "$1.35 per bill" : "$1.50 per bill"}
                  </PlanPricePerBill>
                  <PlanDescription height={"84px"}>
                    Designed for small teams, the Starter plan offers 100 bills
                    per month for just $150. With 3 admin seats included, it’s
                    perfect for those who want to streamline their utility
                    management without breaking the bank.
                  </PlanDescription>
                  <PlanLineItemsContainer>
                    <PlanLineItem>100 bills per month</PlanLineItem>
                    <PlanLineItem>3 seats per subscription</PlanLineItem>
                  </PlanLineItemsContainer>
                  <a
                    href="https://app.buildupward.com/signup?pricing=true"
                    style={{ textDecoration: "none" }}
                  >
                    <PlanButton color={"#AA5EEA"} hoverColor={"#841cd9"}>
                      Select
                    </PlanButton>
                  </a>
                </PlanContainer>

                <PlanContainer>
                  <PlanHeader>Basic</PlanHeader>
                  <PlanPrice>
                    <PlanPriceNumber color={"#03A146"}>
                      {" "}
                      {period === "annual" ? "284" : "315"}
                    </PlanPriceNumber>
                    <PlanPriceSign color={"#03A146"}>$</PlanPriceSign>
                  </PlanPrice>
                  <PlanPriceUnit>
                    {period === "annual"
                      ? "per month ($3,480 billed annually)"
                      : "per month"}
                  </PlanPriceUnit>
                  <PlanPricePerBill color={"#03A146"}>
                    {period === "annual" ? "$1.14 per bill" : "$1.26 per bill"}
                  </PlanPricePerBill>
                  <PlanDescription height={"84px"}>
                    Scale your operations with the Basic plan, featuring 250
                    bills per month for $315. With 7 admin seats, your growing
                    team can efficiently handle more utility bills, ensuring
                    smooth management for your business.
                  </PlanDescription>
                  <PlanLineItemsContainer>
                    <PlanLineItem>250 bills per month</PlanLineItem>
                    <PlanLineItem>7 seats per subscription</PlanLineItem>
                  </PlanLineItemsContainer>
                  <a
                    href="https://app.buildupward.com/signup?pricing=true"
                    style={{ textDecoration: "none" }}
                  >
                    <PlanButton color={"#03A146"} hoverColor={"#027833"}>
                      Select
                    </PlanButton>
                  </a>
                </PlanContainer>

                <PlanContainer>
                  <PlanHeader>Pro</PlanHeader>
                  <PlanPrice>
                    <PlanPriceNumber color={"#FDBA35"}>
                      {period === "annual" ? "450" : "500"}
                    </PlanPriceNumber>
                    <PlanPriceSign color={"#FDBA35"}>$</PlanPriceSign>
                  </PlanPrice>
                  <PlanPriceUnit>
                    {period === "annual"
                      ? "per month ($5,400 billed annually)"
                      : "per month"}
                  </PlanPriceUnit>
                  <PlanPricePerBill color={"#FDBA35"}>
                    {period === "annual" ? "$0.90 per bill" : "$1.00 per bill"}
                  </PlanPricePerBill>
                  <PlanDescription height={"84px"}>
                    Unlock full potential with the Pro plan, which provides 500
                    bills per month for $500. With 20 admin seats, it’s tailored
                    for larger teams looking to manage utilities seamlessly and
                    enhance collaboration across the board.
                  </PlanDescription>
                  <PlanLineItemsContainer>
                    <PlanLineItem>500 bills per month</PlanLineItem>
                    <PlanLineItem>20 seats per subscription</PlanLineItem>
                  </PlanLineItemsContainer>
                  <a
                    href="https://app.buildupward.com/signup?pricing=true"
                    style={{ textDecoration: "none" }}
                  >
                    <PlanButton color={"#FDBA35"} hoverColor={"#E49702"}>
                      Select
                    </PlanButton>
                  </a>
                </PlanContainer>

                <PlanContainer>
                  <PlanHeader>Enterprise</PlanHeader>
                  <PlanPrice>
                    <PlanPriceNumber color={"#00093D"}>
                      {period === "annual" ? "675" : "750"}
                    </PlanPriceNumber>
                    <PlanPriceSign color={"#00093D"}>$</PlanPriceSign>
                  </PlanPrice>
                  <PlanPriceUnit>
                    {period === "annual"
                      ? "per month ($8,100 billed annually)"
                      : "per month"}
                  </PlanPriceUnit>
                  <PlanPricePerBill color={"#00093D"}>
                    {period === "annual" ? "$0.68 per bill" : "$0.75 per bill"}
                  </PlanPricePerBill>
                  <PlanDescription height={"84px"}>
                    For high-demand users, the Enterprise plan delivers 1000
                    bills per month at $750. Enjoy unlimited admin seats,
                    allowing your entire team to collaborate on utility
                    management, perfect for large organizations.
                  </PlanDescription>
                  <PlanLineItemsContainer>
                    <PlanLineItem>1000 bills per month</PlanLineItem>
                    <PlanLineItem>Unlimited seats</PlanLineItem>
                  </PlanLineItemsContainer>
                  <a
                    href="https://app.buildupward.com/signup?pricing=true"
                    style={{ textDecoration: "none" }}
                  >
                    <PlanButton color={"#00093D"} hoverColor={"#0025f5"}>
                      Select
                    </PlanButton>
                  </a>
                </PlanContainer>
              </>
            )}
            {pricingType === "api" && (
              <PlanContainer>
                <PlanHeader>Pay as you go</PlanHeader>
                <PlanPrice>
                  <PlanPriceNumber color={"#3958FF"}>2</PlanPriceNumber>
                  <PlanPriceSign color={"#3958FF"}>$</PlanPriceSign>
                </PlanPrice>
                <PlanPriceUnit>per utility bill</PlanPriceUnit>
                <PlanDescription height={"94px"}>
                  Perfect for occasional users, this plan allows you to pay just
                  $2 per utility bill. Enjoy the flexibility of 2 admin seats to
                  manage your account efficiently, making it ideal for
                  businesses with varying needs.
                </PlanDescription>
                {/* <PlanLineItemsContainer>
                  <PlanLineItem>2 seats per admin account</PlanLineItem>
                </PlanLineItemsContainer> */}
                <a
                  href="https://app.buildupward.com/signup?pricing=true"
                  style={{ textDecoration: "none" }}
                >
                  <PlanButton color={"#3958FF"} hoverColor={"#0A2fff"}>
                    Select
                  </PlanButton>
                </a>
              </PlanContainer>
            )}
          </PlansContainer>
        </PlansCard>
        <OtherSolutionsCard>
          <OtherSolutionsText>
            <OtherSolutionsHeader>
              Do you have specific needs?
            </OtherSolutionsHeader>
            <OtherSolutionsSubtext>
              If your organization needs to process more than a 1000 bills per
              month please reach out to us so we can set up a plan thats right
              for you.
            </OtherSolutionsSubtext>
          </OtherSolutionsText>
          <OtherSolutionsButton>Contact Us</OtherSolutionsButton>
        </OtherSolutionsCard>
      </ContainerPricing>
    </Container>
  );
};

export default Pricing;
