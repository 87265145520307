export const apiByLanguage = {
  generateExtractURLs: {
    cURL: `curl -X POST "https://api.buildupward.com/api/generateUploadUrls" 
        -H "Content-Type: multipart/form-data" 
        -H "Authorization: Bearer YOUR_API_KEY" 
        -F 'data={
            "filenames": ["file1.png", "file2.pdf"],
            "customer": "customer_id_123",
            "detailed": true
        };type=application/json'
        `,
    javascript: `const axios = require('axios');

async function generateUploadUrls() {
    const url = "https://api.buildupward.com/api/generateUploadUrls";
    const apiKey = "YOUR_API_KEY"; // Replace with your actual API key
    const payload = {
        filenames: ["file1.png", "file2.pdf"],
        customer: "customer_id_123",
        detailed: true
    };
            
    try {
        const response = await axios.post(url, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer \${apiKey}"
        },
        transformRequest: (data, headers) => {
            const formData = new FormData();
            for (const key in data) {
                formData.append(key, JSON.stringify(data[key]));
            }
            return formData;
        }
        });
            
        console.log('Response:', response.data);
    } catch (error) {
        console.error('Error generating upload URLs:', error.response ? error.response.data : error.message);
    }
}
        
generateUploadUrls();
        `,
    python: `import requests

def generate_upload_urls():
    url = "https://api.buildupward.com/api/generateUploadUrls"
    api_key = "YOUR_API_KEY"  # Replace with your actual API key
    payload = {
        "filenames": ["file1.png", "file2.pdf"],
        "customer": "customer_id_123",
        "detailed": True
    }

    headers = {
        "Authorization": f"Bearer {api_key}",
    }

    # Sending the request
    try:
        response = requests.post(url, headers=headers, json=payload)

        # Check if the request was successful
        response.raise_for_status()
        
        print('Response:', response.json())
    except requests.exceptions.RequestException as e:
        print('Error generating upload URLs:', e)

generate_upload_urls()`,
    response: `  {
    "success": true,
    "message": "Urls created successfully",
    "data": {
      "requestId": "78694c45-f275-4f11-bcef-c09049407c27",
      "signedUrls": {
        "bill1.pdf": "https://storage.googleapis.com/bucket/bill1.pdf?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential...",
        "bill2.pdf": "https://storage.googleapis.com/bucket/bill2.pdf?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential..."
      },
      "unsupportedFiles": [
        "bill3.txt"
      ]
    }
  }`,
  },
  getRequestData: {
    cURL: `curl -X GET "https://api.buildupward.com/api/getRequestData?requestId=YOUR_REQUEST_ID" 
      -H "Authorization: Bearer YOUR_API_KEY" 
      -H "Accept: application/json"`,
    javascript: `const axios = require('axios');

      const requestId = 'YOUR_REQUEST_ID';
      const apiKey = 'YOUR_API_KEY';
      
      axios.get("https://api.buildupward.com/api/getRequestData?requestId=\${requestId}", {
        headers: {
          'Authorization': "Bearer \${apiKey}",
          'Accept': 'application/json'
        }
      })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });`,
    python: `import requests

      url = "https://api.buildupward.com/api/getRequestData"
      request_id = "YOUR_REQUEST_ID"
      api_key = "YOUR_API_KEY"
      
      headers = {
          "Authorization": f"Bearer {api_key}",
          "Accept": "application/json"
      }
      
      params = {
          "requestId": request_id
      }
      
      response = requests.get(url, headers=headers, params=params)
      
      if response.status_code == 200:
          print(response.json())
      else:
          print(f"Error: {response.status_code}")`,
    response: `     {
        "success": true,
        "message": "Request data retrieved successfully",
        "data": {
          "type": "pdfBills",
          "dateCreated": {
            "_seconds": 1718747980,
            "_nanoseconds": 798000000
          },
          "billsCount": 2,
          "requestId": "dad4bce8-f5cb-4078-a211-995864315e39",
          "dateApproved": {
            "_seconds": 1718747986,
            "_nanoseconds": 869000000
          },
          "customer": "Test Upload",
          "status": "approved",
          "billsData": {
            "unsupportedBills": [],
            "data": {
              "123412341234": {
                "natural gas": {
                  "overview": {
                    "unit": "Therm",
                    "serviceClass": "natural gas",
                    "meterNumbers": [
                      "12341234"
                    ],
                    "totalCharges": 398.13,
                    "utility": "Con Edison",
                    "serviceAddress": "123 E 12 ST",
                    "totalUsage": 250,
                    "tariff": "GS3 Multiple Dwelling Heating",
                    "dates": {
                      "endDate": "2022-06-13",
                      "startDate": "2022-05-12"
                    },
                    "accountNumber": "123412341234",
                    "customer": "123 E 12 ST"
                  },
                  "data": [
                    {
                      "totalSupplyCharges": 152.78,
                      "totalDeliveryCharges": 228.21,
                      "filename": "1.pdf",
                      "endDate": "2022-06-13",
                      "meterNumbers": [
                        "12341234"
                      ],
                      "usage": 250,
                      "confidence": 0.99,
                      "totalCharges": 398.13,
                      "location": "uploadedBills/dad4bce8-f5cb-4078-a211-995864315e39/1.pdf",
                      "id": 0,
                      "numberOfDays": 32,
                      "startDate": "2022-05-12",
                      "billing": {
                        "preivousCharges": 1912.43,
                        "previousPayment": 1912.43,
                        "dueDate": "2022-06-29"
                      }
                    }
                  ]
                },
                "electric": {
                  "overview": {
                    "unit": "kWh",
                    "serviceClass": "electric",
                    "meterNumbers": [
                      "12341234"
                    ],
                    "totalCharges": 833.95,
                    "utility": "Con Edison",
                    "serviceAddress": "123 E 12 STRE ENT",
                    "totalUsage": 3060,
                    "tariff": "EL9 General Large",
                    "dates": {
                      "endDate": "2022-06-13",
                      "startDate": "2022-05-12"
                    },
                    "accountNumber": "123412341234",
                    "customer": "123 E 12 ST"
                  },
                  "data": [
                    {
                      "demandDeliveryCharges": 270.83,
                      "endDate": "2022-06-13",
                      "meterNumbers": [
                        "12341234"
                      ],
                      "usage": 3060,
                      "confidence": 0.99,
                      "demandSupplyCharges": 43.55,
                      "numberOfDays": 32,
                      "demand": 7.2,
                      "totalSupplyCharges": 350.74,
                      "totalDeliveryCharges": 447.3,
                      "filename": "1.pdf",
                      "totalCharges": 833.95,
                      "totalDemandCharges": 314.38,
                      "location": "uploadedBills/dad4bce8-f5cb-4078-a211-995864315e39/1.pdf",
                      "id": 1,
                      "startDate": "2022-05-12",
                      "billing": {
                        "preivousCharges": 1912.43,
                        "previousPayment": 1912.43,
                        "dueDate": "2022-06-29"
                      }
                    }
                  ]
                }
              }
            }
          }
        }
      }`,
  },
  getAllRequests: {
    cURL: `curl -X GET "https://api.buildupward.com/api/requests" 
      -H "Authorization: Bearer YOUR_API_KEY" 
      -H "Accept: application/json"`,
    javascript: `const axios = require('axios');

      const apiKey = 'YOUR_API_KEY';
      
      axios.get('https://api.buildupward.com/api/requests', {
        headers: {
          'Authorization': \`Bearer \${apiKey}\`,
          'Accept': 'application/json'
        }
      })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            console.error('Error: Unrecognized API key');
          } else if (error.response.status === 402) {
            console.error('Error: RequestId not found');
          } else {
            console.error('Error:', error.response.status);
          }
        } else {
          console.error('Request failed:', error.message);
        }
      });`,
    python: `import requests

      url = "https://api.buildupward.com/api/requests"
      api_key = "YOUR_API_KEY"
      
      headers = {
          "Authorization": f"Bearer {api_key}",
          "Accept": "application/json"
      }
      
      response = requests.get(url, headers=headers)
      
      if response.status_code == 200:
          print(response.json())
      elif response.status_code == 401:
          print("Error: Unrecognized API key")
      elif response.status_code == 402:
          print("Error: RequestId not found")
      else:
          print(f"Error: {response.status_code}")`,
    response: `{
        "success": true,
        "message": "Requests retrieved successfully",
        "data": {
          "declined": {
            "78694c45-f275-4f11-bcef-c09049407c27": {
              "accountNumbers": null,
              "dateCreated": {
                "_seconds": 1718303209,
                "_nanoseconds": 717000000
              },
              "billsCount": null,
              "serviceClass": null,
              "requestId": "78694c45-f275-4f11-bcef-c09049407c27",
              "serviceAddress": null,
              "utility": null,
              "totalAccounts": null,
              "customer": "declined test",
              "status": "declined",
              "info": "This request did not contain any supported energy or water bills"
            }
          },
          "approved": {
            "4e620400-235e-4fe9-ba35-54f420c230a0": {
              "accountNumbers": [
                "414229424500002"
              ],
              "billsCount": 1,
              "dateCreated": {
                "_seconds": 1716910745,
                "_nanoseconds": 780000000
              },
              "serviceClass": [
                "natural gas",
                "electric"
              ],
              "requestId": "4e620400-235e-4fe9-ba35-54f420c230a0",
              "serviceAddress": [
                "110 E 13 STRE ENT"
              ],
              "utility": [
                "Con Edison"
              ],
              "totalAccounts": 1,
              "customer": "Test",
              "status": "approved"
            }
          },
          "pending": {
            "1c67a407-8d2d-4040-8fcd-ea03fc1ca4a2": {
              "accountNumbers": null,
              "dateCreated": {
                "_seconds": 1719427765,
                "_nanoseconds": 255000000
              },
              "billsCount": null,
              "serviceClass": null,
              "requestId": "1c67a407-8d2d-4040-8fcd-ea03fc1ca4a2",
              "serviceAddress": null,
              "utility": null,
              "totalAccounts": null,
              "customer": null,
              "status": "pending"
            }
          }
        }
      }`,
  },
  deleteRequest: {
    cURL: `curl -X DELETE "https://api.buildupward.com/api/requests/YOUR_REQUEST_ID" 
      -H "Authorization: Bearer YOUR_API_KEY" 
      -H "Accept: application/json"`,
    javascript: `const axios = require('axios');

      const requestId = 'YOUR_REQUEST_ID';
      const apiKey = 'YOUR_API_KEY';
      
      axios.delete(\`https://api.buildupward.com/api/requests/\${requestId}\`, {
        headers: {
          'Authorization': \`Bearer \${apiKey}\`,
          'Accept': 'application/json'
        }
      })
      .then(response => {
        console.log('Successfully deleted request:', response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            console.error('Error: Unrecognized API key');
          } else if (error.response.status === 402) {
            console.error('Error: RequestId not found');
          } else {
            console.error('Error:', error.response.status);
          }
        } else {
          console.error('Request failed:', error.message);
        }
      });`,
    python: `import requests

      url = "https://api.buildupward.com/api/requests/YOUR_REQUEST_ID"
      api_key = "YOUR_API_KEY"
      
      headers = {
          "Authorization": f"Bearer {api_key}",
          "Accept": "application/json"
      }
      
      response = requests.delete(url, headers=headers)
      
      if response.status_code == 200:
          print("Successfully deleted request:", response.json())
      elif response.status_code == 401:
          print("Error: Unrecognized API key")
      elif response.status_code == 402:
          print("Error: RequestId not found")
      else:
          print(f"Error: {response.status_code}")
      `,
    response: `{
        "success": true,
        "message": "Request data deleted successfully"
}`,
  },
  createUploadForm: {
    cURL: `curl -X POST "https://api.buildupward.com/api/upload-form" 
      -H "Authorization: Bearer YOUR_API_KEY" 
      -H "Accept: application/json" 
      -H "Content-Type: multipart/form-data" 
      -F "name=YOUR_FORM_NAME" 
      -F "id=YOUR_FORM_ID"`,
    javascript: `const axios = require('axios');
      const FormData = require('form-data');
      const fs = require('fs');
      
      const apiKey = 'YOUR_API_KEY';
      const formName = 'YOUR_FORM_NAME';
      const formId = 'YOUR_FORM_ID';
      
      // Create form data
      const form = new FormData();
      form.append('name', formName);
      form.append('id', formId);
      
      axios.post('https://api.buildupward.com/api/upload-form', form, {
        headers: {
          'Authorization': \`Bearer \${apiKey}\`,
          'Accept': 'application/json',
          ...form.getHeaders()  // Use form headers for multipart/form-data
        }
      })
      .then(response => {
        console.log('Successfully created upload form:', response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            console.error('Error: Unrecognized API key');
          } else if (error.response.status === 402) {
            console.error('Error: RequestId not found');
          } else if (error.response.status === 403) {
            console.error('Error: No files associated with this request');
          } else {
            console.error('Error:', error.response.status);
          }
        } else {
          console.error('Request failed:', error.message);
        }
      });
      `,
    python: `import requests

      url = "https://api.buildupward.com/api/upload-form"
      api_key = "YOUR_API_KEY"
      form_name = "YOUR_FORM_NAME"
      form_id = "YOUR_FORM_ID"
      
      # Prepare the form data
      form_data = {
          'name': form_name,
          'id': form_id
      }
      
      # Prepare headers
      headers = {
          "Authorization": f"Bearer {api_key}",
          "Accept": "application/json"
      }
      
      # Send the POST request
      response = requests.post(url, headers=headers, files=form_data)
      
      # Handle the response
      if response.status_code == 200:
          print("Successfully created upload form:", response.json())
      elif response.status_code == 401:
          print("Error: Unrecognized API key")
      elif response.status_code == 402:
          print("Error: RequestId not found")
      elif response.status_code == 403:
          print("Error: No files associated with this request")
      else:
          print(f"Error: {response.status_code}")
      `,
    response: `{
        "url": "app.buildupward.com/customer-bills-upload/api/9a7ead93c068d7ec645f3590658e0ba3:fa23ce99950cbd3aa09s8dfa0s9f236013f2db0fc5d9a539791sdaf09as1eb5562b",
        "customer": "Example Customer"
}`,
  },
  testWebhook: {
    cURL: `curl -X GET "https://api.buildupward.com/api/testWebhook" 
      -H "Authorization: Bearer YOUR_API_KEY" 
      -H "Accept: application/json"
      `,
    javascript: `const axios = require('axios');

      const apiKey = 'YOUR_API_KEY';
      
      axios.get('https://api.buildupward.com/api/testWebhook', {
        headers: {
          'Authorization': \`Bearer \${apiKey}\`,
          'Accept': 'application/json'
        }
      })
      .then(response => {
        console.log('Successfully sent test webhook message:', response.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            console.error('Error: Unrecognized API key');
          } else if (error.response.status === 402) {
            console.error('Error: Webhook endpoint not found');
          } else {
            console.error('Error:', error.response.status);
          }
        } else {
          console.error('Request failed:', error.message);
        }
      });
      `,
    python: `import requests

      url = "https://api.buildupward.com/api/testWebhook"
      api_key = "YOUR_API_KEY"
      
      # Prepare the headers
      headers = {
          "Authorization": f"Bearer {api_key}",
          "Accept": "application/json"
      }
      
      # Send the GET request
      response = requests.get(url, headers=headers)
      
      # Handle the response
      if response.status_code == 200:
          print("Successfully sent test webhook message:", response.json())
      elif response.status_code == 401:
          print("Error: Unrecognized API key")
      elif response.status_code == 402:
          print("Error: Webhook endpoint not found")
      else:
          print(f"Error: {response.status_code}")
      `,
    response: `{
        "success": true,
        "message": "Test webhook sent successfully"
}`,
  },
  getAllPDFs: {
    cURL: `curl -X GET "https://api.buildupward.com/api/pdfs/YOUR_REQUEST_ID" 
      -H "Authorization: Bearer YOUR_API_KEY" 
      -H "Accept: application/zip"
      `,
    javascript: `const axios = require('axios');
      const fs = require('fs');
      
      const requestId = 'YOUR_REQUEST_ID';
      const apiKey = 'YOUR_API_KEY';
      
      axios.get(\`https://api.buildupward.com/api/pdfs/\${requestId}\`, {
        headers: {
          'Authorization': \`Bearer \${apiKey}\`,
          'Accept': 'application/zip',
          responseType: 'arraybuffer' // To handle binary response
        }
      })
      .then(response => {
        // Save the zip file to the local filesystem
        fs.writeFileSync('pdfs.zip', response.data);
        console.log('Successfully retrieved pdf files and saved to pdfs.zip');
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            console.error('Error: Unrecognized API key');
          } else if (error.response.status === 402) {
            console.error('Error: RequestId not found');
          } else if (error.response.status === 403) {
            console.error('Error: No files associated with this request');
          } else {
            console.error('Error:', error.response.status);
          }
        } else {
          console.error('Request failed:', error.message);
        }
      });
      `,
    python: `import requests

      url = "https://api.buildupward.com/api/pdfs/YOUR_REQUEST_ID"
      api_key = "YOUR_API_KEY"
      
      # Prepare the headers
      headers = {
          "Authorization": f"Bearer {api_key}",
          "Accept": "application/zip"
      }
      
      # Send the GET request
      response = requests.get(url, headers=headers)
      
      # Handle the response
      if response.status_code == 200:
          # Save the zip file to the local filesystem
          with open('pdfs.zip', 'wb') as f:
              f.write(response.content)
          print("Successfully retrieved pdf files and saved to pdfs.zip")
      elif response.status_code == 401:
          print("Error: Unrecognized API key")
      elif response.status_code == 402:
          print("Error: RequestId not found")
      elif response.status_code == 403:
          print("Error: No files associated with this request")
      else:
          print(f"Error: {response.status_code}")
      `,
    response: `<zip binary string>`,
  },
  getPDF: {
    cURL: `curl -X POST "https://api.buildupward.com/api/getFile" 
      -H "Authorization: Bearer YOUR_API_KEY" 
      -H "Accept: application/pdf" 
      -F "requestId=YOUR_REQUEST_ID" 
      -F "fileName=YOUR_FILE_NAME"
      `,
    javascript: `const axios = require('axios');
      const FormData = require('form-data');
      
      const requestId = 'YOUR_REQUEST_ID';
      const fileName = 'YOUR_FILE_NAME';
      const apiKey = 'YOUR_API_KEY';
      
      // Create form data
      const form = new FormData();
      form.append('requestId', requestId);
      form.append('fileName', fileName);
      
      axios.post('https://api.buildupward.com/api/getFile', form, {
        headers: {
          'Authorization': \`Bearer \${apiKey}\`,
          'Accept': 'application/pdf',
          ...form.getHeaders() // Use form headers for multipart/form-data
        },
        responseType: 'arraybuffer' // To handle binary response
      })
      .then(response => {
        // Save the PDF file to the local filesystem
        require('fs').writeFileSync('output.pdf', response.data);
        console.log('Successfully retrieved the PDF file and saved to output.pdf');
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            console.error('Error: Unrecognized API key');
          } else if (error.response.status === 402) {
            console.error('Error: RequestId not found');
          } else if (error.response.status === 403) {
            console.error('Error: No files associated with this request');
          } else {
            console.error('Error:', error.response.status);
          }
        } else {
          console.error('Request failed:', error.message);
        }
      });
      `,
    python: `import requests

      url = "https://api.buildupward.com/api/getFile"
      api_key = "YOUR_API_KEY"
      request_id = "YOUR_REQUEST_ID"
      file_name = "YOUR_FILE_NAME"
      
      # Prepare the form data
      files = {
          'requestId': (None, request_id),  # Use None as filename for non-file fields
          'fileName': (None, file_name)
      }
      
      # Prepare the headers
      headers = {
          "Authorization": f"Bearer {api_key}",
          "Accept": "application/pdf"
      }
      
      # Send the POST request
      response = requests.post(url, headers=headers, files=files)
      
      # Handle the response
      if response.status_code == 200:
          # Save the PDF file to the local filesystem
          with open('output.pdf', 'wb') as f:
              f.write(response.content)
          print("Successfully retrieved the PDF file and saved to output.pdf")
      elif response.status_code == 401:
          print("Error: Unrecognized API key")
      elif response.status_code == 402:
          print("Error: RequestId not found")
      elif response.status_code == 403:
          print("Error: No files associated with this request")
      else:
          print(f"Error: {response.status_code}")
      `,
    response: `<pdf binary string>`,
  },
  arvindGenerateExtractURLs: {
    cURL: `curl -X POST "https://api.buildupward.com/api/generateUploadUrls" 
        -H "Content-Type: multipart/form-data" 
        -H "Authorization: Bearer YOUR_API_KEY" 
        -F 'data={
            "filenames": ["C&I_Electric.pdf"],
            "customer": "customer_id_123",
            "detailed": true
        };type=application/json'
        `,
    javascript: `const axios = require('axios');

async function generateUploadUrls() {
    const url = "https://api.buildupward.com/api/generateUploadUrls";
    const apiKey = "YOUR_API_KEY"; // Replace with your actual API key
    const payload = {
        filenames: ["C&I_Electric.pdf"],
        customer: "customer_id_123",
        detailed: true
    };
            
    try {
        const response = await axios.post(url, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer \${apiKey}"
        },
        transformRequest: (data, headers) => {
            const formData = new FormData();
            for (const key in data) {
                formData.append(key, JSON.stringify(data[key]));
            }
            return formData;
        }
        });
            
        console.log('Response:', response.data);
    } catch (error) {
        console.error('Error generating upload URLs:', error.response ? error.response.data : error.message);
    }
}
        
generateUploadUrls();
        `,
    python: `import requests

def generate_upload_urls():
    url = "https://api.buildupward.com/api/generateUploadUrls"
    api_key = "YOUR_API_KEY"  # Replace with your actual API key
    payload = {
        "filenames": ["C&I_Electric.pdf"],
        "customer": "customer_id_123",
        "detailed": True
    }

    headers = {
        "Authorization": f"Bearer {api_key}",
    }

    # Sending the request
    try:
        response = requests.post(url, headers=headers, json=payload)

        # Check if the request was successful
        response.raise_for_status()
        
        print('Response:', response.json())
    except requests.exceptions.RequestException as e:
        print('Error generating upload URLs:', e)

generate_upload_urls()`,
    response: `  {
    "success": true,
    "message": "Urls created successfully",
    "data": {
      "requestId": "78694c45-f275-4f11-bcef-c09049407c27",
      "signedUrls": {
        "C&I_Electric.pdf": "https://storage.googleapis.com/upward-a899f.appspot.com/uploadedBills/ff2b5b68-9f1b-4519-840b-365ff9fff24e/a1041bf5-33b1-4280-ab2e-3bf05dec7d30/C%26I_Electric.pdf?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=firebase-adminsdk-yq1ly%40upward-a899f.iam.gserviceaccount.com%2F20241012%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20241012T215041Z&X-Goog-Expires=900&X-Goog-SignedHeaders=content-type%3Bhost&X-Goog-Signature=2e788e1f778753fd6c9e8fcdbfed5960ba9254916409297db37f89a6337738652705d45dc920d100ad249178e5a52055eba96f3c5907a2bbd02691e6941a5de0b2c93cf95a262f040f6c27a35a4134968d581cf4a7ee3c9b4b7f5f6b73fd2f7984cb0f7cb369a2f3771cd2c9495923d5597822d588d67c705ff2aacc86241fd17c6e8bc10072fb46d5d839bab1c45a0054711c927422b059061c0e6286820d18bc20f84da2d2a232f027c692ad29cd73a7c9883f869d14fde83e06f56b8789b6b52191ed45df41487d132e10096d5500d4ade5dd36f2d1846e9258047223bc68676928d78a34b6e18cc442656beac74b040aeb0728c171a2c46bed26c605ff5e"
      },
      "unsupportedFiles": [
        "bill3.txt"
      ]
    }
  }`,
  },
  arvindGCSUpload: {
    cURL: `curl --location --request PUT 'https://storage.googleapis.com/upward-a899f.appspot.com/uploadedBills/ff2b5b68-9f1b-4519-840b-365ff9fff24e/a1041bf5-33b1-4280-ab2e-3bf05dec7d30/C%26I_Electric.pdf?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=firebase-adminsdk-yq1ly%40upward-a899f.iam.gserviceaccount.com%2F20241012%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20241012T215041Z&X-Goog-Expires=900&X-Goog-SignedHeaders=content-type%3Bhost&X-Goog-Signature=2e788e1f778753fd6c9e8fcdbfed5960ba9254916409297db37f89a6337738652705d45dc920d100ad249178e5a52055eba96f3c5907a2bbd02691e6941a5de0b2c93cf95a262f040f6c27a35a4134968d581cf4a7ee3c9b4b7f5f6b73fd2f7984cb0f7cb369a2f3771cd2c9495923d5597822d588d67c705ff2aacc86241fd17c6e8bc10072fb46d5d839bab1c45a0054711c927422b059061c0e6286820d18bc20f84da2d2a232f027c692ad29cd73a7c9883f869d14fde83e06f56b8789b6b52191ed45df41487d132e10096d5500d4ade5dd36f2d1846e9258047223bc68676928d78a34b6e18cc442656beac74b040aeb0728c171a2c46bed26c605ff5e' 
      --header 'Content-Type: application/pdf' 
      --data '@/Users/arvindramgopal/Documents/Upward Demo File Uploads/C&I_Electric.pdf'`,
    javascript: `const axios = require('axios');
      let data = '<file contents here>';
      
      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: 'https://storage.googleapis.com/upward-a899f.appspot.com/uploadedBills/ff2b5b68-9f1b-4519-840b-365ff9fff24e/7c0bf3d5-2676-4bcf-b45a-3251a0b6fde7/C%26I_Electric.pdf?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=firebase-adminsdk-yq1ly%40upward-a899f.iam.gserviceaccount.com%2F20241013%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20241013T002648Z&X-Goog-Expires=900&X-Goog-SignedHeaders=content-type%3Bhost&X-Goog-Signature=b30cf31a495be625984e6daf56f70d74e9738705b9c5c16af4365387b1f48dc9c0593963ae668b28dd6b8a5a620691b9505c976a574e476863481456bfbab0cee9a7023d109de87a033f99d38755935eb0b83d9fa115ddaf7ac3d576b4a54fba9736afe3a67289f78e493ea7e8f85e8aec204f7608a185ff1ef62cf70c4f8129a1464f87b550397ec1fa760ab4bab2fd8dc688f5c18d9bfc53cd28530c970eeeea265fd8e08a4ccdd6d12234b616df2507e8b516cec5e9bf46eb9bb59af4701b70d48f6501cc789ee080f53f0a1a635e27fec7d8fc56ae2b30d2199d2d6cb2d7c7092d850f6a610b8a6a1914ad65b4363b40a7e6bf1f78ece3f9e57e7452210f',
        headers: { 
          'Content-Type': 'application/pdf'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
      `,
    python: `import requests

      url = "https://storage.googleapis.com/upward-a899f.appspot.com/uploadedBills/ff2b5b68-9f1b-4519-840b-365ff9fff24e/7c0bf3d5-2676-4bcf-b45a-3251a0b6fde7/C%26I_Electric.pdf?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=firebase-adminsdk-yq1ly%40upward-a899f.iam.gserviceaccount.com%2F20241013%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20241013T002648Z&X-Goog-Expires=900&X-Goog-SignedHeaders=content-type%3Bhost&X-Goog-Signature=b30cf31a495be625984e6daf56f70d74e9738705b9c5c16af4365387b1f48dc9c0593963ae668b28dd6b8a5a620691b9505c976a574e476863481456bfbab0cee9a7023d109de87a033f99d38755935eb0b83d9fa115ddaf7ac3d576b4a54fba9736afe3a67289f78e493ea7e8f85e8aec204f7608a185ff1ef62cf70c4f8129a1464f87b550397ec1fa760ab4bab2fd8dc688f5c18d9bfc53cd28530c970eeeea265fd8e08a4ccdd6d12234b616df2507e8b516cec5e9bf46eb9bb59af4701b70d48f6501cc789ee080f53f0a1a635e27fec7d8fc56ae2b30d2199d2d6cb2d7c7092d850f6a610b8a6a1914ad65b4363b40a7e6bf1f78ece3f9e57e7452210f"
      
      payload = "<file contents here>"
      headers = {
        'Content-Type': 'application/pdf'
      }
      
      response = requests.request("PUT", url, headers=headers, data=payload)
      
      print(response.text)
      `,
  },
  arvindGetAllRequests: {
    cURL: `curl --location 'https://api.buildupward.com/api/getAllRequests' 
      --header 'apiKey: <YOUR-API-KEY>'`,
    javascript: `const axios = require('axios');

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://api.buildupward.com/api/getAllRequests',
        headers: { 
          'apiKey': '<YOUR-API-KEY>'
        }
      };
      
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
      `,
    python: `import requests

      url = "https://api.buildupward.com/api/getAllRequests"
      
      payload = {}
      headers = {
        'apiKey': '<YOUR-API-KEY>'
      }
      
      response = requests.request("GET", url, headers=headers, data=payload)
      
      print(response.text)
      `,
    response: `{
          "success": true,
          "message": "Requests retrieved successfully",
          "data": {
              "declined": {
                "78694c45-f275-4f11-bcef-c09049407c27": {
                    "accountNumbers": null,
                    "dateCreated": {
                        "_seconds": 1718303209,
                        "_nanoseconds": 717000000
                    },
                    "billsCount": null,
                    "serviceClass": null,
                    "requestId": "78694c45-f275-4f11-bcef-c09049407c27",
                    "serviceAddress": null,
                    "utility": null,
                    "totalAccounts": null,
                    "customer": "declined test",
                    "status": "declined",
                    "info": "This request did not contain any supported energy or water bills"
                }
              },
              "approved": {
                "7c0bf3d5-2676-4bcf-b45a-3251a0b6fde7": {
                    "accountNumbers": [
                        "414229424500002"
                    ],
                    "billsCount": 1,
                    "dateCreated": {
                        "_seconds": 1716910745,
                        "_nanoseconds": 780000000
                    },
                    "serviceClass": [
                        "natural gas",
                        "electric"
                    ],
                    "requestId": "4e620400-235e-4fe9-ba35-54f420c230a0",
                    "serviceAddress": [
                        "110 E 13 STRE ENT"
                    ],
                    "utility": [
                        "Con Edison"
                    ],
                    "totalAccounts": 1,
                    "customer": "Test",
                    "status": "approved"
                }
              },
              "pending": {
                "7cb75e2d-477e-44d8-b56d-28453c3cd556": {
                    "accountNumbers": null,
                    "dateCreated": {
                        "_seconds": 1721229087,
                        "_nanoseconds": 90000000
                    },
                    "billsCount": null,
                    "serviceClass": null,
                    "requestId": "7cb75e2d-477e-44d8-b56d-28453c3cd556",
                    "serviceAddress": null,
                    "utility": null,
                    "totalAccounts": null,
                    "customer": "Accord Test",
                    "status": "pending"
                }
              }
          }
      }`,
  },
  arvindGetRequestData: {
    cURL: `curl --location 'https://api.buildupward.com/api/getRequest/7c0bf3d5-2676-4bcf-b45a-3251a0b6fde7' 
      --header 'apiKey: <YOUR-API-KEY>'`,
    javascript: `const axios = require('axios');

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://api.buildupward.com/api/getRequest/7c0bf3d5-2676-4bcf-b45a-3251a0b6fde7',
        headers: { 
          'apiKey': '<YOUR-API-KEY>'
        }
      };
      
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
      `,
    python: `import requests

      url = "https://api.buildupward.com/api/getRequest/7c0bf3d5-2676-4bcf-b45a-3251a0b6fde7"
      
      payload = {}
      headers = {
        'apiKey': '<YOUR-API-KEY>'
      }
      
      response = requests.request("GET", url, headers=headers, data=payload)
      
      print(response.text)
      `,
    response: `{
        "success": true,
        "message": "Request data retrieved successfully",
        "data": {
            "dateCreated": {
                "_seconds": 1728779208,
                "_nanoseconds": 168000000
            },
            "customer": "pdfBills",
            "status": "approved",
            "requestId": "7c0bf3d5-2676-4bcf-b45a-3251a0b6fde7",
            "billsCount": 1,
            "dateApproved": {
                "_seconds": 1728779499,
                "_nanoseconds": 627000000
            },
            "billsData": {
                "unsupportedBills": [],
                "data": {
                    "257255": {
                        "fuel oil #2": {
                            "overview": {
                                "totalUsage": 2500,
                                "totalCharges": 7137.41,
                                "accountNumber": "257255",
                                "utility": "UNITED METRO ENERGY",
                                "customer": "320 WEST 76 CORP",
                                "serviceAddress": "320 W 76TH ST, NY NY 10023",
                                "serviceClass": "fuel oil #2",
                                "unit": "Gallons (US)",
                                "dates": {}
                            },
                            "data": [
                                {
                                    "totalCharges": 7137.41,
                                    "usage": 2500,
                                    "billing": {
                                        "dueDate": "2023-05-22",
                                        "previousCharges": null,
                                        "previousPayment": null
                                    },
                                    "filename": "C&I_Electric.pdf",
                                    "confidence": 0.98,
                                    "deliveryDate": "2023-04-22"
                                }
                            ]
                        }
                    }
                }
            }
        }
    }`,
  },
  arvindCreateForm: {
    cURL: `curl --location 'https://api.buildupward.com/api/createForm' 
      --header 'apiKey: 34ab953a-1ff1-42d3-8858-0cd2164f9639' 
      --form 'customer="arvind@buildupward.com"'`,
    javascript: `const axios = require('axios');
      const FormData = require('form-data');
      let data = new FormData();
      data.append('customer', 'arvind@buildupward.com');
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.buildupward.com/api/createForm',
        headers: { 
          'apiKey': '34ab953a-1ff1-42d3-8858-0cd2164f9639', 
          ...data.getHeaders()
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
      `,
    python: `import requests

      url = "https://api.buildupward.com/api/createForm"
      
      payload = {'customer': 'arvind@buildupward.com'}
      files=[
      
      ]
      headers = {
        'apiKey': '34ab953a-1ff1-42d3-8858-0cd2164f9639'
      }
      
      response = requests.request("POST", url, headers=headers, data=payload, files=files)
      
      print(response.text)
      `,
    response: `{
        "success": true,
        "message": "Form created successfully",
        "data": {
            "url": "https://app.buildupward.com/customer-bills-upload/api/284149b33e6e55238a47001b61c927d3:02d99b61b3db74e7d3619e7fdeaebdb3e57bcf1b60e5136c8fefb32b88e48bc787bb538b720c2289d1d6064691f70c45",
            "customer": null
        }
    }`,
  },
};
