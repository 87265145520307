import React, { useState } from "react";
import { Container, Headline, Heading } from "../styles/apiStepByStep.js";
import CodeBlock from "../components/syntaxHighlighter.js";
import { apiByLanguage } from "../utils/codeByLanguage";
import { generateUploadUrls } from "../apiCalls/generateUploadUrls.js";
import { isNil } from "lodash";

const APIStepByStep = () => {
  const [language, setLanguage] = useState("cURL");
  const [generateUrlLoading, setGenerateUrlLoading] = useState(null);
  const handleSetLanguage = (e) => {
    setLanguage(e.target.value);
  };

  const handleSetGenerateUrlLoading = (value) => {
    console.log("setting it", value);
    setGenerateUrlLoading(value);
  };

  return (
    <Container>
      <Headline>
        In this guide you will learn how to use the Upward API to upload files,
        extract data, and manage your data requests. By the end you will know
        how to get started with the Upward API and integrate it into your
        applications and workflows.
      </Headline>
      <Heading>Do you have access to the files to upload?</Heading>
      <Headline>
        If you do not have access to the files you need to upload for analysis
        please scroll down to the section titled{" "}
        <a href="#no-bills">
          Don't have access to the necessary utility bills? No problem
        </a>
        , otherwise if you do you are in the right place. The next few steps
        will show you how to upload your files and see your exisiting data
        requests.
      </Headline>
      <Heading>Generate URLs for the files being uploaded</Heading>
      <Headline>
        To make our system scalable to allow for a high volume of bill
        processing, we pre generate urls to upload your files to Google Cloud
        Services. We are going to use the /api/generateUploadUrls endpoint to
        generate these urls to prepare our uploads.
      </Headline>
      <Headline>
        Let's pretend we have a customer called Arvind and he has the following
        file to upload: {`C&I_Electric.pdf`}. To upload his file we are going to
        make the following HTTP request:
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindGenerateExtractURLs}
        type={"code"}
        language={language}
        setLanguage={handleSetLanguage}
        styleType={"guide"}
      />
      <Headline>
        The following is the response you get from calling the endpoint above.
        Notice the signed urls in the response. You will use this url in the
        call described below to upload your file to GCS. The requestId in the
        response will be used later on when you need to access your uploaded
        bills and analytics on those bills. Also keep in mind the unsupported
        bills key in the response. This is an array of files you may have tried
        to upload that do not meet the requirements of our system.
      </Headline>
      <CodeBlock
        type={"response"}
        code={apiByLanguage.arvindGenerateExtractURLs.response}
        styleType={"guide"}
      />
      <Heading>Upload files to Google Cloud Services</Heading>
      <Headline>
        For each signed url that you get in the response you will need to make
        an HTTP PUT call using the urls provided in the response to upload your
        files to GCS. See the example below to understand how to make the call
        to the endpoint. Notice the url in the PUT endpoint below is the same as
        the url in the response of the previous POST call.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindGCSUpload}
        language={language}
        setLanguage={handleSetLanguage}
        type={"code"}
        styleType={"guide"}
      />
      <Headline>
        There is no response object from this PUT call, you should just see a
        status of 200 if the call was successfully run.
      </Headline>
      <Heading id="no-bills">
        Don't have access to the necessary utility bills? No problem!
      </Heading>
      <Headline>
        In this part of the guide you will see how you can create a form that
        you can send to your customers for them to upload their bills into our
        system which you can then access as needed.
      </Headline>
      <Headline>
        Our system has a feature that allows you to create a form which you can
        send to your customers where they can upload there bills. And then you
        can use our other API calls to see if they have successfully uploaded
        their bills and get the data shortly after.
      </Headline>
      <Heading>Create a form to send to your customers</Heading>
      <Headline>
        The following API call is used to create a form which will be provided
        by the url in the response which you can send out to whoever you'd like
        so that they can upload their bills asynchronously.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindCreateForm}
        type={"code"}
        language={language}
        setLanguage={handleSetLanguage}
        styleType={"guide"}
      />
      <Headline>
        Below is the response from the createForm API call. It includes the url
        that you can send to your customers or anyone who will be uploading
        bills to your account.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindCreateForm.response}
        type={"response"}
        styleType={"guide"}
      />
      <Heading>Get All Requests</Heading>
      <Headline>
        Now that the file or files have been uploaded to GCS or you have used
        the create form link to get your customers to upload their bills our
        system starts to process them. At first they will be pending requests
        and once they have been processed they move to approved requests, and in
        the slim chance something goes wrong it moves to a declined request. Now
        we will make an API call that allows us to see all of the requests that
        have been made and their respective statuses.
      </Headline>

      <CodeBlock
        code={apiByLanguage.arvindGetAllRequests}
        type={"code"}
        language={language}
        setLanguage={handleSetLanguage}
        styleType={"guide"}
      />
      <Headline>
        You'll see in the response below that there are requests in the states
        declined, approved, and pending. This is an exhaustive list of all the
        requests made on the current account.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindGetAllRequests.response}
        type={"response"}
        styleType={"guide"}
      />
      <Heading>Get a specific request</Heading>
      <Headline>
        Once a request has been approved, you can pull all the data and
        analytics that were performed on the bills in that request. Look at the
        API call below to see how to get your data for a specific request by
        using the requestId you got when you first generated the signed urls.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindGetRequestData}
        language={language}
        setLanguage={handleSetLanguage}
        type={"code"}
        styleType={"guide"}
      />
      <Headline>
        This API call gives all the data for the requestId provided. you can
        chain these API calls together to get the data for multiple requests
        that have been approved. Below is the response from the API call to get
        the data of the request using the specified requestId.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindGetRequestData.response}
        type={"response"}
        styleType={"guide"}
      />
      <Headline>
        At this point you have been introduced to the Upward API. Go ahead and
        keeping making calls to our API for all your utility bill needs. If you
        have any questions please don't hesistate to reach out to us at
        support@buildupward.com.
      </Headline>
    </Container>
  );
};

export default APIStepByStep;
