import React, { useState } from "react";
import { Container, Icon } from "../../styles/mobile/howItWorks";
import MobileContentList from "../mobile/contentList";
import GetBills from "../../assets/GetBills.svg";

import ReviewData from "../../assets/ReviewData.svg";
import ShareData from "../../assets/ShareData.svg";

const MobileHowItWorks = () => {
  const [whichImage, setWhichImage] = useState(0);
  const setImageFromChild = (index) => {
    setWhichImage(index);
  };
  const images = [GetBills, ReviewData, ShareData];
  const items = [
    {
      icon: <Icon>01</Icon>,
      title: "Upload bills",
      description:
        "Simply drag and drop your bills to get started. Or send an upload link directly  to your customers and skip emailing back and forth for PDFs.",
    },
    {
      icon: <Icon>02</Icon>,
      title: "Analyze data",
      description:
        "Get detailed insights into usage, costs and billing, demand, sustainability and more.",
    },
    {
      icon: <Icon>03</Icon>,
      title: "Share and export",
      description:
        "Collaborate with your team directly in the upward platform. Export data in multiple formats to easily integrate with your current process.",
    },
  ];
  return (
    <Container>
      <MobileContentList items={items} setImageFromChild={setImageFromChild} />
    </Container>
  );
};

export default MobileHowItWorks;
